<template>
  <div class="grade">
    <div
      style="display: flex; justify-content: space-between; margin-bottom: 20px"
    >
      <div style="display: flex">
        <el-input
          placeholder="请输入搜索关键字"
          v-model="searchWord"
        ></el-input>
      </div>
      <div class="operation">
        <el-button @click="add" type="primary"> + 新增</el-button>
      </div>
    </div>
    <div class="table">
      <el-table
        :data="tableData"
        style="width: 100%; margin-bottom: 20px"
        row-key="id"
        border
        default-expand-all
        :tree-props="{ children: 'sysJobGradeList' }"
        height="450px"
      >
        <el-table-column prop="content" label="职级名称"> </el-table-column>
        <el-table-column prop="instructions" label="说明"> </el-table-column>
        <el-table-column prop="remark" label="备注"> </el-table-column>
        <el-table-column prop="updateTime" label="更新日期" min-width="100">
        </el-table-column>
        <el-table-column prop="updateByName" label="更新人员">
        </el-table-column>
        <el-table-column label="操作" class-name="operation" min-width="100">
          <template v-slot="scope">
            <span @click="addChild(scope.row)" v-if="isLeaf(scope.row)"
              >新增子职级</span
            >
            <span @click="edit(scope.row, scope)">编辑</span>
            <span @click="remove(scope.row)" class="red">删除</span>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <template>
      <el-dialog :visible.sync="visible" :title="titleMap[type]">
        <el-form :model="form" ref="form" :rules="formRules">
          <el-form-item label="职级名称" prop="content">
            <el-input v-model="form.content"></el-input>
          </el-form-item>
          <el-form-item label="说明" prop="instructions">
            <el-input v-model="form.instructions"></el-input>
          </el-form-item>
          <el-form-item label="备注" prop="remark">
            <el-input v-model="form.remark"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer">
          <el-button @click="visible = false">取 消</el-button>
          <el-button type="primary" @click="confirm">确 定</el-button>
        </div>
      </el-dialog>
    </template>
  </div>
</template>

<script>
import { filterFunc, findLayer } from "../../../utils/utils";
export default {
  name: "Grade",
  data() {
    return {
      searchWord: "",
      visible: false,
      type: "",
      titleMap: {
        edit: "编辑",
        addChild: "新增子内容",
        add: "新增",
      },
      form: {
        content: "",
        instructions: "",
        remark: "",
      },
      tableData: [],
      initData: [],
      formRules: {
        content: [this.$formValidator.empty("职级名称")],
        instructions: [this.$formValidator.empty("说明")],
        remark: [this.$formValidator.empty("备注")],
      },
    };
  },
  mounted() {
    this.getData();
  },
  watch: {
    searchWord(word) {
      this.tableData = JSON.parse(JSON.stringify(this.initData));
      this.tableData = filterFunc(this.tableData, word, "sysJobGradeList");
    },
    visible(val) {
      if (!val) {
        this.form = {
          content: "",
          instructions: "",
          remark: "",
        };
      }
    },
  },
  methods: {
    isLeaf(item) {
      // 最多只允许增加到第三层
      return findLayer(item, this.tableData, "sysJobGradeList") < 3;
    },
    getData() {
      this.$api.sysapi.sysJobGradeList().then((res) => {
        this.tableData = res.data;
        this.initData = JSON.parse(JSON.stringify(this.tableData));
      });
    },
    open() {
      this.visible = true;
    },
    add() {
      this.type = "add";
      this.open();
    },
    edit(row) {
      this.type = "edit";
      this.open(); // 需要先打开窗口，才能写值
      Object.keys(this.form).forEach((key) => {
        ["content", "id", "instructions", "remark"].forEach((key) => {
          this.form[key] = row[key];
        });
      });
    },
    addChild(row) {
      this.type = "addChild";
      this.open();
      this.form.pid = row.id;
    },
    remove(row) {
      this.$confirm("确定要删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$api.sysapi.sysJobGradeDelete(row.id).then((res) => {
            this.visible = false;
            this.getData();
          });
        })
        .catch(() => {});
    },
    confirm() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          const reqMap = {
            edit: this.$api.sysapi.sysJobGradeUpdate,
            add: this.$api.sysapi.sysJobGradeInsert,
            addChild: this.$api.sysapi.sysJobGradeInsert,
          };
          reqMap[this.type](this.form).then((res) => {
            this.visible = false;
            this.getData();
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.grade {
  background-color: white;
  padding: 20px;
  .operation {
    span {
      cursor: pointer;
      color: #5c6be8;
      border-right: 1px solid lightgray;
      padding: 0 4px;
      &:last-child {
        border-right: none;
        color: red;
      }
    }
  }
}
</style>

<style lang="scss">
.grade {
  .el-table th {
    background-color: rgb(238, 241, 246);
  }
  .el-table--border td {
    border-right: none;
  }
}
</style>